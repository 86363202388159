<template>    
        <NavBar />
        <!-- Get Started Section -->
        <header class="jumbotron text-center">
            <div class="container">
                <h1 class="display-4">Revolutionizing Salon Management</h1>
                <p class="lead">A one-stop solution for all your salon needs.</p>
                <a href="#" class="btn btn-primary btn-lg">Get Started</a>
            </div>
        </header>
        <!-- Booking Section -->
        <section class="booking-section py-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-9">
                        <div class="booking-form bg-white p-4 rounded shadow">
                            <div v-if="searchFormVisible">
                            <form class="row g-3" @submit.prevent="searchServices">
                                <!-- Service Input -->
                                <div class="col-md-4">
                                    <!-- <v-select :options="serviceCategories" v-model="serviceQuery" label="Search Service or Treatment"></v-select> -->
                                    <v-select
                                    :options="serviceOptions"
                                    v-model="serviceQuery"
                                    label="label"
                                    :reduce="service => service.value"
                                    placeholder="Search Service or Treatment"
                                    ></v-select>
                                </div>
                                <!-- Postcode or City Input -->
                                <div class="col-md-3">
                                    <div class="position-relative">
                                        <input type="text" class="form-control" id="searchInput" placeholder=" " aria-label="Location" v-model="locationQuery">
                                        <label for="searchInput" class="position-absolute">
                                            <i class="fas fa-search"></i> Zip code or City
                                    </label>
                                    </div>
                                </div>
                                <!-- Date Input -->
                                <div class="col-md-3">
                                <input type="date" v-model="dateQuery" :min="minDate" :max="maxDate" class="form-control" placeholder="Select Date">
                                </div>
                                <!-- Search Button -->
                                <div class="col-md-2">
                                <button type="submit" class="btn btn-primary w-100">Search</button>
                                </div>
                            </form>
                            <!-- Search Results -->
                            <div v-if="searching" class="alert alert-info" role="alert">
                            Searching...
                            </div>
                            <div v-if="searchResults.length > 0" class="mt-4">
                                <h5>Search Results: </h5>
                                <div class="list-group">
                                    <a v-for="(result, index) in searchResults" :key="result.id" class="list-group-item list-group-item-action">
                                    <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-1">Name: {{ result.nickName }}</h5>
                                        <small>{{ formatDate(result.date) }}</small>
                                    </div>
                                    <p class="mb-1">City: {{ result.city }}</p>
                                    <p class="mb-1">Price: ${{ result.cost }}</p>
                                    <p class="mb-1">Duration: {{ result.duration }} Minutes</p>
                                    <p>Rating: ({{ result.rating }})
                                        <i v-for="_ in starRatings[index].fullStars" :key="'full-' + _" class="bi bi-star-fill golden-star"></i>
                                        <i v-if="starRatings[index].halfStar" class="bi bi-star-half golden-star"></i>
                                        <i v-for="_ in starRatings[index].emptyStars" :key="'empty-' + _" class="bi bi-star golden-star"></i>
                                    </p>

                                    <!-- Dynamic Alert -->
                                      <div class="alert" :class="'alert-' + alertType" role="alert" v-if="showAlert">
                                        {{ alertMessage }}
                                      </div>

                                    <button @click="openBookingForm(result)" class="btn btn-primary">Book Now</button>

                                      <!-- Inline Booking Appointment Form -->
                                      <booking-form
                                        v-if="result.showBookingForm"
                                        :show="result.showBookingForm"
                                        :booking-details="localBookingDetails"
                                        :startTimeOptions="localBookingDetails.startTimeOptions"
                                        :serviceTypeOptionss="localBookingDetails.serviceTypeOptionss"
                                        :is-loading="isLoading"
                                        @submit-booking="submitBookingForm"
                                        @cancel-booking="() => result.showBookingForm = false"
                                      />

                                    </a>                                    
                                </div>
                                
                                <!-- Pagination -->
                                <b-pagination
                                v-model="currentPage"
                                :total-rows="searchResults.length"
                                :per-page="resultsPerPage"
                                aria-controls="my-table"
                                class="custom-pagination"
                                ></b-pagination>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center text-center mt-5">
                    <!-- Search Icon and Text -->
                    <div class="col-lg-4 mb-4">
                        <div class="d-flex align-items-center justify-content-center">
                            <i class="fas fa-search fa-2x"></i>
                            <strong>Explore.</strong>
                        </div>
                        <p class="mb-0">Find top beauty and wellness spots near you.</p>
                    </div>
                    
                    <!-- Book Icon and Text with Vertical Divider -->
                    <div class="col-lg-4 mb-4 border-start border-end">
                        <div class="d-flex align-items-center justify-content-center">
                            <i class="fas fa-book fa-2x"></i>
                            <strong>Reserve.</strong>
                        </div>
                        <p class="mb-0">Secure your spot with effortless online booking.</p>
                    </div>
                    
                    <!-- Enjoy Icon and Text -->
                    <div class="col-lg-4 mb-4">
                        <div class="d-flex align-items-center justify-content-center">
                            <i class="fas fa-heart fa-2x"></i>
                            <strong>Relax.</strong>
                        </div>
                        <p class="mb-0">Sit back and let us handle the finer details.</p>
                    </div>
                </div>
            </div>
        </section>                                                    
        <!-- Features Section -->
        <section class="features-section container text-center my-5">
            <div class="features-title">
                <h2>Features</h2>
                <div class="divider"></div>
            </div>
            <div class="row">
                <!-- Feature 1 -->
                <div class="col-md-4 feature-card">
                    <a href="#" class="feature-link">
                        <img src="https://images.unsplash.com/photo-1595475884562-073c30d45670?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwzfHxzYWxvbnxlbnwwfHx8fDE2OTY2NjI3NTd8MA&ixlib=rb-4.0.3&q=80&w=400" alt="Customer Bookings" class="img-fluid feature-image">
                        <h4 class="feature-title">Customer Bookings</h4>
                        <p class="feature-description">Effortlessly manage and schedule appointments.</p>
                    </a>
                </div>
                <!-- Feature 2 -->
                <div class="col-md-4 feature-card">
                    <a href="#" class="feature-link">
                        <img src="https://images.unsplash.com/photo-1562322140-8baeececf3df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHw0fHxzYWxvbnxlbnwwfHx8fDE2OTY2NjI3NTd8MA&ixlib=rb-4.0.3&q=80&w=400" alt="E-commerce" class="img-fluid feature-image">
                        <h4 class="feature-title">E-commerce Functionalities</h4>
                        <p class="feature-description">Browse and shop for the best beauty products online.</p>
                    </a>
                </div>
                <!-- Feature 3 -->
                <div class="col-md-4 feature-card">
                    <a href="#" class="feature-link">
                        <img src="https://images.unsplash.com/photo-1560066984-138dadb4c035?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHw1fHxzYWxvbnxlbnwwfHx8fDE2OTY2NjI3NTd8MA&ixlib=rb-4.0.3&q=80&w=400" alt="Real-time Updates" class="img-fluid feature-image">
                        <h4 class="feature-title">Real-time Updates</h4>
                        <p class="feature-description">Stay updated with the latest salon news and updates.</p>
                    </a>
                </div>
            </div>
        </section>
        <AppFooter />
        <!-- For the Login Modal -->
        <admin-login v-if="showLoginModal" :is-modal="true" @close-modal="showLoginModal = false" />
</template>
<script>
import NavBar from './NavBar.vue';
import AppFooter from './AppFooter.vue';

import { db } from '@/firebaseConfig'; // Adjust the path according to your project structure
//import {collection, query, where, getDocs} from "firebase/firestore";
import {collection, addDoc} from "firebase/firestore";
import { format } from 'date-fns';//Date format
//Search Algolia
// Initialize Algolia clients and indices
import algoliasearch from 'algoliasearch/lite';
const searchClientUsers = algoliasearch('J8C7YM6WV8', '0c5b01a09b0fbd3e3881025138b68f4e');
const usersIndex = searchClientUsers.initIndex('search_users');

const searchClientServices = algoliasearch('J8C7YM6WV8', 'a0c868e6808fb1d45c068cdf0fda30ef');
const servicesIndex = searchClientServices.initIndex('search_services');

import vSelect from 'vue-select';
const searchClientServiceCategory = algoliasearch('J8C7YM6WV8', '776f2bfee822800102fe93f04cd5fa52');
const ServiceCategoryIndex = searchClientServiceCategory.initIndex('search_serviceCategory');
//Service Booking
import BookingForm from './BookingForm.vue';

//Admin Login
import AdminLogin from './backend/AdminLogin.vue';

export default {
  components: {
    NavBar,
    AppFooter,
    BookingForm,
    vSelect,
    AdminLogin
  },
  //For the Search of the Services
  data() {
    return {
      isLoading: false,//for the submit appointment 
      bookingDetails: {
      beauticianId: '',
      userId: sessionStorage.getItem('userId'),
      ratingId: 'none',
      serviceId: '',
      cost: 0,
      createdAt: new Date(),
      startTime: '',
      startTimeOptions: [],
      serviceType: '',
      serviceTypeOptionss: [],
      endTime: 'none',
      notes: 'None',
      paymentStatus: 'pending',
      status: 'pending'
      },
      serviceQuery: '',
      locationQuery: '',
      searchResults: [],
      //Bootstrap Alert
      showAlert: false,
      alertType: 'danger',
      alertMessage: '',
      searching: false,
      dateQuery: '',
      bookingFormVisible: false,
      selectedService: null,
      searchFormVisible: true,
      serviceOptions: [],
      localBookingDetails: { ...this.bookingDetails },
      showBookingForm: false,
      currentPage: 1,
      resultsPerPage: 10,
      //For the Login Modal
      showLoginModal: false,
    };
  },
  
  methods: {
    openBookingForm(result) {
      console.log('openBookingForm called', result);
      console.log('Result object before showBookingForm:', result);
      
      // Check if user is logged in
      if (!sessionStorage.getItem('userToken')) {
        this.showLoginModal = true;
        return; // Stop further execution of the function
      }

      const requiredProperties = ['userId', 'serviceTypeId', 'cost', 'duration'];
      const missingOrInvalidProperties = requiredProperties.filter(prop => !result || result[prop] == null);

      if (missingOrInvalidProperties.length === 0) {
        this.selectedService = result;
        this.localBookingDetails = {
          ...this.bookingDetails,
          beauticianId: result.userId,
          serviceId: result.serviceTypeId,          
          nickName: result.nickName,
          city: result.city,
          cost: result.cost,
          duration: result.duration,
          serviceTypeOptionss: result.serviceTypeOptions,//For the home or salon
          startTimeOptions: result.startTime//For the time slot
        };
        
        // Toggle the booking form for the selected result
        this.searchResults = this.searchResults.map(searchResult => {
          if (searchResult === result) {
            return { ...searchResult, showBookingForm: !searchResult.showBookingForm };
          }
          return { ...searchResult, showBookingForm: false };
        });
      } else {
        console.error('Invalid result object, cannot show booking form. Missing or invalid properties:', missingOrInvalidProperties, 'Result object:', result);
      }
    },

    async submitBookingForm(bookingData) {
      this.isLoading = true;
      console.log("Booking Data Prepared: ", bookingData);
      try {
        // Add logic to submit the localBookingDetails to Firebase
        // For example:
        const docRef = await addDoc(collection(db, "appointments"), bookingData);
        console.log("Document written with ID: ", docRef.id);

        // Hide the booking form for the specific service that was booked
        // Use a unique identifier (like 'id') for comparison
        this.searchResults = this.searchResults.map(result => {
          if (result.id === this.selectedService.id) {
            return { ...result, showBookingForm: false };
          }
          return result;
        });

        this.showAlert = true;
        this.alertType = 'success';
        this.alertMessage = 'Booking successful!';

        // Set a timeout to hide the alert after 2 seconds
        setTimeout(() => {
          this.showAlert = false;
        }, 2000); // 2000 milliseconds = 2 seconds

      } catch (e) {
        console.error("Error adding document: ", e);
        this.showAlert = true;
        this.alertType = 'danger';
        this.alertMessage = 'Booking failed. Please try again later.';

        // Also hide the error alert after 2 seconds
        setTimeout(() => {
          this.showAlert = false;
        }, 2000);
      } finally {
          this.isLoading = false; // Reset loading state whether success or failure
        }
    },

    async fetchServiceCategories() {//service name
    try {
      const response = await ServiceCategoryIndex.search('');
      this.serviceOptions = response.hits.map(hit => ({ label: hit.name, value: hit.objectID }));
    } catch (error) {
      console.error('Error fetching service categories:', error);
    }
    
  },
  async searchServices() {
  this.searching = true;

  if (!this.serviceQuery || !this.locationQuery || !this.dateQuery) {
    this.showAlert = true;
    this.alertType = 'warning';
    this.alertMessage = 'Please enter a service, location (city or zip code), and date to search.';
    this.searching = false;
    return;
  }

  try {
    // Search in the services index
    const servicesResponse = await servicesIndex.search(this.serviceQuery, {
      filters: `date:"${this.dateQuery}"`,
    //const servicesResponse = await servicesIndex.search(this.serviceQuery.value, {
      //filters: `date:"2023-10-27"`,
      // Add any other search parameters you need here
    });

    // Search in the users index
    // const usersResponse = await usersIndex.search('', {
    //     filters: `zipCode:"${this.locationQuery}" OR city:"${this.locationQuery}"`,
    //   //filters: `zipCode:"84054"`,
    // });
    const usersResponse = await usersIndex.search(this.locationQuery, {
    optionalFilters: [`zipCode:"${this.locationQuery}"`, `city:"${this.locationQuery}"`],
    });


    // Combine results based on userID
    const combinedResults = servicesResponse.hits
  .map(service => {
    const user = usersResponse.hits.find(user => user.objectID === service.userId);
    if (!user) {
      console.log('No match found for service:', service);
    }
    return user ? { ...service, ...user, showBookingForm: false } : null;
  })
  .filter(result => result !== null);

    console.log('Combined Results:', combinedResults);

    if (combinedResults.length === 0) {
      this.searchResults = [];
      this.showAlert = true;
      this.alertType = 'warning';
      this.alertMessage = 'No results found for the selected criteria.';
    } else {
      this.searchResults = combinedResults;
      this.showAlert = false;
    }
    console.log('Services Response:', servicesResponse.hits);
    console.log('Users Response:', usersResponse.hits);

      } 
      catch (error) {
        console.error('Search failed:', error);
        this.showAlert = true;
        this.alertType = 'danger';
        this.alertMessage = 'Search failed. Please try again later.';
      } finally {
        this.searching = false;
      }

      // Make sure to reset currentPage to 1 when a new search is performed
      this.currentPage = 1;
    },
    formatDate(dateString) { // Date Format
      return format(new Date(dateString), 'MMMM d, yyyy');
    },
    bookingComplete() {
      this.bookingFormVisible = false;
      this.searchFormVisible = true;
      this.selectedService = null;
    },
    cancelBooking(result) {
      this.searchFormVisible = true;
      result.showBookingForm = false;
    }
  },
  created() {
  this.fetchServiceCategories();
},
  computed: {
    // Convert the ratings to an array of star types
    starRatings() {
        return this.searchResults.map(result => {
            const rating = parseFloat(result.rating);
            const fullStars = Math.floor(rating);
            const halfStar = rating % 1 >= 0.5 ? 1 : 0;
            const emptyStars = 5 - fullStars - halfStar;
            return { fullStars, halfStar, emptyStars };
        });
    },
    //Date search form the today date to the end of the week
    minDate() {
        return new Date().toISOString().split('T')[0];
    },
    maxDate() {
    const today = new Date();
    const maxRangeDate = new Date(today);
    
    // Set the maximum range date to 15 days from today
    maxRangeDate.setDate(today.getDate() + 15);

    return maxRangeDate.toISOString().split('T')[0];
    },
    paginatedResults() {
      const start = (this.currentPage - 1) * this.resultsPerPage;
      const end = start + this.resultsPerPage;
      return this.searchResults.slice(start, end);
    },
    computed: {
      localBookingDetails() {
        return { ...this.bookingDetails };
      }
    }
  }
  // rest of your HomePage script
};
</script>
<style>
#service
{
    width:100%;
}
.golden-star {
  color: #FFD700; /* This is a gold color */
}
/* Pagination */
.custom-pagination .page-item.active .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white; /* Set the text color for the active link */
}
.custom-pagination .page-link {
    color: var(--primary-color); /* Set the default text color for the links */
}
.custom-pagination .page-link:hover {
    background-color: var(--primary-color);
    color: white; /* Set the text color for the hovered link */
}
.booking-form{
    border-radius:20px !important;
}
/* Search for the zip code style */
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
  top: 0;
  left: 0;
  padding: .375rem .75rem;
  pointer-events: none;
  transition: all 0.2s; /* Smooth transition for label */
}

#searchInput {
  padding-left: 2rem; /* Adjusted padding to make space for the icon */
}

#searchInput:focus + .position-absolute,
#searchInput:not(:placeholder-shown) + .position-absolute {
  transform: scale(0.85) translateY(-10px); /* Shrink and move up the label */
  opacity: 0; /* Hide the label */
}
@import "vue-select/dist/vue-select.css";
</style>