<template>
    <!-- Sidebar -->
    <div class="col-md-3">
          <ul class="list-group">
            <li class="list-group-item">
              <router-link class="nav-link" to="/">Front End</router-link>
            </li>
            <li class="list-group-item">
              <router-link class="nav-link" to="/portal-dashboard">Dashboard</router-link>
            </li>
            <li v-if="userRole === 'beautician'" class="list-group-item">
                <router-link v-if="userRole === 'beautician'" class="nav-link" to="/view-my-services">View My Services</router-link>                
            </li>
            <li v-if="userRole === 'beautician'" class="list-group-item">
                <router-link v-if="userRole === 'beautician'" class="nav-link" to="/my-services">Add My Services</router-link>                
            </li>
            <li v-if="userRole === 'beautician'" class="list-group-item">
                <router-link v-if="userRole === 'beautician'" class="nav-link" to="/received-appointment">Received Appointments</router-link>
            </li>
            <li v-if="userRole === 'customer'" class="list-group-item">
                <router-link v-if="userRole === 'customer'" class="nav-link" to="/my-appointment">My Appointment</router-link>
            </li>
            <!-- Add other sidebar items as needed -->
          </ul>
    </div>    
</template>
<script>
export default {
    name: 'PortalSidebar',
    created() {
        // Fetch the user's role after the component is created
        // Fetch the user's role from session storage
        this.userRole = sessionStorage.getItem('userRole');
    }
}
</script>
<style scoped>
/* Highlight the active link */
.router-link-exact-active {
    text-decoration: underline;
    color: var(--primary-color) !important;
}
</style>