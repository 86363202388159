// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDVrrh4BQuLP3WyK7GIGm3NGetjLWbmA7w",
    authDomain: "beautlyn.firebaseapp.com",
    projectId: "beautlyn",
    storageBucket: "beautlyn.appspot.com",
    messagingSenderId: "140919697148",
    appId: "1:140919697148:web:a037ad6dac99ea46bff179"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//for the realtime database
//const database = getDatabase(app);

//for the firestore database
const db = getFirestore(app);

export { db };