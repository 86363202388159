<template>
    <!-- Footer -->
    <footer class="py-4">
            <div class="container text-center">
                <div>
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                    <a href="#"><i class="fab fa-twitter"></i></a>
                    <a href="#"><i class="fab fa-instagram"></i></a>
                </div>
                <div>
                    <a href="#">Privacy Policy</a> | <a href="#">Terms of Service</a>
                </div>
                <p>&copy; 2023 Beautlyn System. All rights reserved.</p>
            </div>
        </footer>
</template>
<script>
export default {
  name: 'AppFooter'
  // any other logic or data properties for the footer
}
</script>