<template>
  <PortalNav @logout-event="logout"></PortalNav>  
  <!-- Main Content -->
  <div class="container my-4">
    <div class="row">
      <!-- Sidebar -->
      <PortalSidebar/>
      <!-- Entry Form -->
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <h3 class="text-center mb-4">Add My New Service</h3>
            <form @submit.prevent="addService">
              <!-- Service Type Dropdown -->
              <div class="mb-3">
                <label for="serviceType" class="form-label">Service Name</label>
                <select class="form-control" id="serviceType" v-model="serviceData.serviceTypeId" @change="onServiceTypeChange">
                  <option value="">Select a Service Name</option>
                  <option v-for="category in serviceCategories" :key="category.id" :value="category.id">{{ category.name }}</option>
                </select>
              </div>
              
              <!-- Service Type Dropdown -->
              <div class="mb-3">
                <label for="serviceTypeOptions" class="form-label">Service Type</label>
                <select class="form-control" id="serviceTypeOptions" v-model="serviceData.serviceTypeOptions" multiple>
                  <option v-for="option in serviceTypeOptions" :key="option" :value="option">{{ option }}</option>
                </select>
              </div>

              <!-- Date Field -->
              <div class="mb-3">
                <label for="serviceDate" class="form-label">Date</label>
                <input type="date" class="form-control" id="serviceDate" v-model="serviceData.date" :min="minDate" :max="maxDate" required>
              </div>

              <!-- Service Description -->
              <div class="mb-3">
                <label for="serviceDescription" class="form-label">Description</label>
                <textarea class="form-control" id="serviceDescription" v-model="serviceData.description" required></textarea>
              </div>

              <!-- Cost Field -->
              <div class="mb-3">
                <label for="serviceCost" class="form-label">Cost ($)</label>
                <input type="text" class="form-control" id="serviceCost" v-model="serviceData.cost" @input="validateCost" required>
                <div v-if="invalidCost" class="text-danger">Please enter a valid cost (e.g., 30.2)</div>
              </div>

              <!-- Service Duration -->
              <div class="mb-3">
                <label for="serviceDuration" class="form-label">Duration (minutes)</label>
                <input type="number" class="form-control" id="serviceDuration" v-model.number="serviceData.duration" required>
              </div>

              <!-- Time Slots Multi-select -->
              <div class="mb-3">
                <label for="timeSlots" class="form-label">Time Slots</label>
                <select class="form-control" id="timeSlots" v-model="serviceData.startTime" multiple>
                  <option v-for="time in timeSlots" :key="time" :value="time">{{ time }}</option>
                </select>
              </div>
              
              <!-- Success Alert -->
              <div v-if="showSuccessAlert" class="alert alert-success" role="alert">
                {{ successMessage }}
              </div>

              <!-- Submit Button with Loading Indicator -->
              <button type="submit" class="btn btn-primary" :disabled="isLoading">
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="isLoading"> Loading...</span>
                <span v-else>Add Service</span>
              </button>
            </form>
            
          </div>
        </div>
      </div>  
    </div>
  </div>
  <AppFooter/>
</template>

<script>
import PortalNav from '../../components/backend/PortalNav.vue';
import AppFooter from '../../components/AppFooter.vue';
import PortalSidebar from '../../components/backend/PortalSidebar.vue';
import { db } from '@/firebaseConfig';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';

export default {
  name: 'MyServices',
  components: { AppFooter, PortalNav, PortalSidebar },

  data() {
    return {
      serviceData: {
        serviceTypeId: '',
        date: '',
        description: '',
        cost: 0,
        duration: 0,
        startTime: [],
        userId: sessionStorage.getItem('userId'),
        serviceTypeOptions: [], // New property for service type options
      },
      serviceCategories: [],
      showSuccessAlert: false,
      successMessage: '',
      isLoading: false, // Loading state for the form
      minDate: new Date().toISOString().split('T')[0],
      maxDate: new Date(new Date().setDate(new Date().getDate() + 15)).toISOString().split('T')[0],
      timeSlots: this.generateTimeSlots(),
      invalidCost: false, //for the cost validation
    };
  },

  methods: {
    validateCost() {
      // Regular expression to validate the cost format (e.g., 30.2)
      const regex = /^\d+(\.\d{1,2})?$/;
      this.invalidCost = !regex.test(this.serviceData.cost);
    },

    async fetchServiceCategories() {
      console.log("fetchServiceCategories is called"); // Check if the method is called
      try {
        const q = query(collection(db, "serviceCategory"), where("status", "==", "active"));
        const querySnapshot = await getDocs(q);
        this.serviceCategories = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Fetched service categories:", this.serviceCategories); // Log to check the data
      } catch (e) {
        console.error("Error fetching service categories:", e);
      }
    },

    onServiceTypeChange() {
      const selectedCategory = this.serviceCategories.find(category => category.id === this.serviceData.serviceTypeId);
      if (selectedCategory) {
        this.serviceData.description = selectedCategory.description;
        this.serviceData.cost = selectedCategory.cost;
        this.serviceData.duration = selectedCategory.duration;
        // Update service type options based on the selected service category
        this.serviceTypeOptions = selectedCategory.serviceType;
      } else {
        // Reset service type options if no category is selected
        this.serviceTypeOptions = [];
      }
    },

    generateTimeSlots() {
    const slots = [];
    const hours = [9, 11, 13, 15, 17, 19]; // 24-hour format hours for the start times

    hours.forEach(hour => {
      const startTime = this.formatTime(hour);
      const endTime = this.formatTime(hour + 1);
      slots.push(`${startTime} to ${endTime}`);
    });

    return slots;
    },

    formatTime(hour) {
      const hour12 = hour % 12 === 0 ? 12 : hour % 12; // Convert to 12-hour format
      const amPm = hour < 12 || hour === 24 ? 'AM' : 'PM';
      return `${hour12}:00 ${amPm}`;
    },

    async addService() {
      console.log("Service data before submission:", this.serviceData); // Log to check the data
      this.isLoading = true; // Start loading
      try {
        // Check for duplicate service on the same day
        const servicesRef = collection(db, "services");
        const q = query(servicesRef, where("serviceTypeId", "==", this.serviceData.serviceTypeId), where("date", "==", this.serviceData.date), where("userId", "==", sessionStorage.getItem('userId')));

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          // If there's a duplicate service on the same day
          this.showSuccessAlert = true;
          this.successMessage = "Duplicate My service on the same day, please choose a new date to add.";
          setTimeout(() => {
            this.showSuccessAlert = false;
          }, 3000); // Hide after 3 seconds
        } else {
        // Proceed with adding the new service
        const docRef = await addDoc(servicesRef, this.serviceData);
        console.log("Service added with ID:", docRef.id);

        // Set success message and show alert
        this.successMessage = `My Service added successfully`;
        this.showSuccessAlert = true;
        // Optional: Hide the alert after a few seconds
        setTimeout(() => {
          this.showSuccessAlert = false;
        }, 3000); // Hide after 3 seconds
        }//Else loop ended

        // Clear form after successful submission
        this.serviceData = { name: '', description: '', cost: 0, duration: 0 };
        // Additional logic (e.g., showing success alert) goes here
      } catch (e) {
        console.error("Error adding service:", e);
        // Handle the error (e.g., show an error alert)
      } finally {
        this.isLoading = false; // Stop loading
      }
    }

  },

  mounted() {
    console.log("Service created");
    this.fetchServiceCategories();
  }
};
</script>