<template>
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg">
            <div class="container">
                <router-link class="navbar-brand" to="/"><img :src="logoImage" alt="Beaut Lyn Logo" width="170"></router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul class="navbar-nav ml-auto">
                        <li class="nav-item">
                            <router-link class="nav-link" to="/">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/about">About</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/services">Services</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/products">Products</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link v-if="!alertSuccess" class="nav-link" to="/portal-login">Login</router-link>
                            <router-link v-if="alertSuccess === 'true'" class="nav-link" to="/portal-dashboard">Dashboard</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" to="/contact">Contact</router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
</template>
<script>
export default {  
    data() {
        return {
        logoImage: require('@/assets/logo.png')
        }
    },
    name: 'NavBar',
    created() {
        // Fetch the user's role after the component is created
        // Fetch the user's role from session storage
        this.alertSuccess = sessionStorage.getItem('alertSuccess');
    }
}
</script>
<style scoped>
/* Highlight the active link */
.router-link-exact-active {
    text-decoration: underline;
    color: var(--secondary-color) !important;
}
</style>