<template>
  <PortalNav @logout-event="logout"></PortalNav>
      <!-- Main Content -->
      <div class="container mt-4">
        <div class="row">
            <!-- Sidebar -->
            <PortalSidebar/>
            <div class="col-md-9">
                    <!-- Entry Form -->
                    <div class="card">
                        <div class="card-body">
                            <h3 class="text-center mb-4">Received Appointment</h3>
                            <!-- Bootstrap 5 Progress Bar for Loading Animation -->
                            <div v-if="isLoading" class="progress">
                              <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  :style="{ width: progress + '%' }"
                                  aria-valuenow="progress"
                                  aria-valuemin="0"
                                  aria-valuemax="100">
                                {{ progress }}%
                              </div>
                            </div>
                            <!-- Data Table -->
                            <table v-else class="table table-bordered table-striped">
                              <thead class="thead-dark">
                                <tr>
                                  <th scope="col">S.N</th>
                                  <th>Date</th>
                                  <th>Name & Number</th>
                                  <th>Service Type</th>
                                  <th>Time Slots</th>
                                  <th>Note</th>
                                  <th>Payment Status</th>
                                  <th>Approval</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(appointment, index) in appointments" :key="appointment.id">
                                  <td>{{ index + 1 }}</td> <!-- Displaying the serial number -->    
                                  <td>{{ formatDate(appointment.createdAt) }}</td>
                                  <td>
                                    {{ appointment.userName }}
                                    <span class="badge bg-danger-subtle text-danger-emphasis rounded-pill">{{ appointment.userNumber }}</span>
                                  </td>
                                  
                                  <td>{{ appointment.serviceType }}</td>
                                  <td>
                                    <span class="badge bg-danger-subtle text-danger-emphasis rounded-pill">{{ appointment.startTime }}</span>
                                  </td>
                                  <td>
                                    <span v-if="appointment.notes === 'None'" class="badge text-bg-secondary rounded-pill">{{ appointment.notes }}</span>
                                    <span v-else-if="appointment.notes !== 'None'" class="badge text-bg-danger rounded-pill">{{ appointment.notes }}</span>
                                  </td>
                                  <td>
                                    <span v-if="appointment.paymentStatus === 'pending'" class="badge text-bg-warning rounded-pill">pending</span>
                                    <span v-else-if="appointment.paymentStatus === 'paid'" class="badge text-bg-success rounded-pill">paid</span>
                                    <!-- Include other conditions if there are more service types -->
                                  </td>
                                  <td>
                                    <span v-if="appointment.status === 'pending'" class="badge text-bg-warning rounded-pill">pending</span>
                                    <span v-else-if="appointment.status === 'confirmed'" class="badge text-bg-success rounded-pill">confirmed</span>
                                    <span v-else-if="appointment.status === 'denied'" class="badge text-bg-danger rounded-pill">denied</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                        </div> 
                    </div>
            </div>
        </div> 
      </div>    
    <br><br><br><br>
  <AppFooter/>
</template>
<script>
import axios from 'axios';
import PortalNav from '../../components/backend/PortalNav.vue';
import AppFooter from '../../components/AppFooter.vue';
import PortalSidebar from '../../components/backend/PortalSidebar.vue';
//For the Firebase 
import { ref, onMounted } from 'vue';
import { db } from '@/firebaseConfig'; // Adjust the path according to your Firebase configuration file
import { collection, getDocs, query, where, doc, getDoc} from 'firebase/firestore';

export default {
    name: 'ReceivedAppointment',
    components: { AppFooter, PortalNav, PortalSidebar },
    methods: {
      async logout() {
        try {
            const response = await axios.post('https://us-central1-beautlyn.cloudfunctions.net/api/logout');
            
            if (response.data.success) {
            // Clear any user data or tokens from local storage or cookies
            sessionStorage.removeItem('userToken'); // Example if you're using Session storage
            sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('alertSuccess');

            // Redirect to the login page
            this.$router.push('/portal-login');
            } else {
            console.error('Error logging out:', response.data.message);
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
        }//Logout End
    },//method End
    setup() {
      const appointments = ref([]);
      const isLoading = ref(true); // Loading state
      const progress = ref(0);//progress bar
      const SessionUserID = sessionStorage.getItem('userId'); // Retrieve the user ID from sessionStorage

      const incrementProgress = () => {
        if (progress.value < 100) {
          progress.value += 10; // Increment by 5% or as needed
          setTimeout(incrementProgress, 100); // Adjust timing as needed
        }
      };

      const fetchAppointments = async () => {
  incrementProgress();
  isLoading.value = true;

  if (SessionUserID) {
    console.warn("Session checked");
    const q = query(collection(db, "appointments"), where("beauticianId", "==", SessionUserID));
    const querySnapshot = await getDocs(q);
    
    let appointmentsWithUserDetails = [];

    for (const docSnapshot of querySnapshot.docs) {
      const appointment = { id: docSnapshot.id, ...docSnapshot.data() };
      const userRef = doc(db, "users", appointment.userId);
      try {
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();
          appointmentsWithUserDetails.push({ 
            ...appointment, 
            userName: userData.name, 
            userNumber: userData.phoneNumber
          });
        } else {
          console.warn("No user found for ID:", appointment.userId);
          appointmentsWithUserDetails.push(appointment);
        }
      } catch (error) {
        console.error("Error fetching user data for appointment:", appointment.id, error);
        appointmentsWithUserDetails.push(appointment);
      }
    }

    appointments.value = appointmentsWithUserDetails.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
  }

  setTimeout(() => {
    isLoading.value = false;
    progress.value = 100;
  }, 200);
};

      const formatDate = (timestamp) => {
        if (timestamp) {
          const date = timestamp.toDate();
          const year = date.getFullYear();
          const month = date.toLocaleString('default', { month: 'short' });
          const day = date.getDate();

          return `${month} ${formatDay(day)}, ${year}`;
        }
        return '';
      };

      const formatDay = (day) => {
        if (day > 3 && day < 21) return day + 'th';
        switch (day % 10) {
          case 1:  return day + "st";
          case 2:  return day + "nd";
          case 3:  return day + "rd";
          default: return day + "th";
        }
      }

      onMounted(fetchAppointments);

      return { appointments, formatDate, isLoading, progress, incrementProgress };
    }//Setup End
}
</script>

<style>
.table td, .table th {
  text-align: center;
  vertical-align: middle;
}
</style>