<template>
    <NavBar />
    <div class="container mt-5">
      <h2 class="text-center mb-4">Our Products</h2>
      <div class="row">
        <div class="col-md-4 mb-4" v-for="(product, index) in products" :key="index">
          <div class="card">
            <img :src="product.image" class="service-img card-img-top" :alt="product.title">
            <div class="card-body">
              <h5 class="card-title">{{ product.title }}</h5>
              <p class="card-text">{{ product.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppFooter />
  </template>
  
  <script>
    import NavBar from './NavBar.vue';
    import AppFooter from './AppFooter.vue';
  export default {
    name: "GeneralProducts",
    components: {
    NavBar,
    AppFooter
    },
    data() {
      return {
        products: [
          { title: "Hair Brush", description: "Coming Soon", image: "https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwxfHxzYWxvbiUyMHByb2R1Y3RzfGVufDB8fHx8MTY5NzMxMzcwM3ww&ixlib=rb-4.0.3&q=80&w=400" },
          { title: "Salon Magazine", description: "Coming Soon", image: "https://images.unsplash.com/photo-1522337094846-8a818192de1f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwyfHxzYWxvbiUyMHByb2R1Y3RzfGVufDB8fHx8MTY5NzMxMzcwM3ww&ixlib=rb-4.0.3&q=80&w=400" },
          { title: "Salon Chair", description: "Coming Soon", image: "https://images.unsplash.com/photo-1554519934-e32b1629d9ee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwzfHxzYWxvbiUyMHByb2D1Y3RzfGVufDB8fHx8MTY5NzMxMzcwM3ww&ixlib=rb-4.0.3&q=80&w=400" },
          { title: "Salon Pens", description: "Coming Soon", image: "https://images.unsplash.com/photo-1527799595389-46c4c3aa0df2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHw0fHxzYWxvbiUyMHByb2R1Y3RzfGVufDB8fHx8MTY5NzMxMzcwM3ww&ixlib=rb-4.0.3&q=80&w=400" },
          { title: "Salon Interior", description: "Coming Soon", image: "https://images.unsplash.com/photo-1521590832167-7bcbfaa6381f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHw1fHxzYWxvbiUyMHByb2D1Y3RzfGVufDB8fHx8MTY5NzMxMzcwM3ww&ixlib=rb-4.0.3&q=80&w=400" },
          { title: "Salon Product", description: "Coming Soon", image: "https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwxfHxzYWxvbiUyMHByb2D1Y3RzfGVufDB8fHx8MTY5NzMxMzcwM3ww&ixlib=rb-4.0.3&q=80&w=400" }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .service-img{
    height:40vh;
  }
  </style>
  