import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styles/style.css';  // Importing the global stylesheet
import BootstrapVue3 from 'bootstrap-vue-3';//Importing the Bootstrap directly in the vue
import VueSelect from "vue-select";//vue-select@beta

const app = createApp(App);
app.use(router);
app.use(BootstrapVue3);
app.component("v-select", VueSelect); // Registering the v-select component globally
app.mount('#app');