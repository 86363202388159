<template>
  <PortalNav @logout-event="logout"></PortalNav>
      <!-- Main Content -->
        <div class="container mt-4">
            <div class="row">
            <!-- Sidebar -->
            <PortalSidebar/>
                <div class="col-md-9">
                    <!-- Entry Form -->
                    <div class="card">
                        <div class="card-body">
                            <h3 class="text-center mb-4">User Profile</h3>
                            <form @submit.prevent="updateProfile">
                                <div class="mb-3">
                                <label for="name" class="form-label">Full Name</label>
                                <input type="text" class="form-control" id="name" v-model="userProfile.name">
                                </div>
                        
                                <div class="mb-3">
                                <label for="nickName" class="form-label">Nickname</label>
                                <input type="text" class="form-control" id="nickName" v-model="userProfile.nickName">
                                </div>
                                
                                <div class="mb-3">
                                <label for="country" class="form-label">Country</label>
                                <input type="text" class="form-control" id="country" v-model="userProfile.country">
                                </div>

                                <div class="mb-3">
                                <label for="state" class="form-label">State</label>
                                <input type="text" class="form-control" id="state" v-model="userProfile.state">
                                </div>

                                <div class="mb-3">
                                <label for="city" class="form-label">City</label>
                                <input type="text" class="form-control" id="city" v-model="userProfile.city">
                                </div>
                        
                                <div class="mb-3">
                                <label for="zipCode" class="form-label">Zip Code</label>
                                <input type="text" class="form-control" id="zipCode" v-model="userProfile.zipCode">
                                </div>

                                <!-- Bootstrap Alert for Update Success -->
                                <div v-if="showSuccessAlert" class="alert alert-success" role="alert">
                                Profile updated successfully!
                                </div>

                                <!-- Update Button with Loading Indicator -->
                                <button type="submit" class="btn btn-primary" :disabled="isLoading">
                                    <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span v-if="isLoading"> Loading...</span>
                                    <span v-else>Update Profile</span>
                                </button>

                            </form>        
                    </div>        
                </div>            
            </div>                
        </div>                            
    </div>
    <br><br><br><br>
  <AppFooter/>
  </template>
  
<script>
import axios from 'axios';
import PortalNav from '../../components/backend/PortalNav.vue';
import AppFooter from '../../components/AppFooter.vue';
import PortalSidebar from '../../components/backend/PortalSidebar.vue';
import { db } from '@/firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export default {
  name: 'UserProfile',
  components: { AppFooter, PortalNav, PortalSidebar },
  data() {
    return {
      userProfile: {
        name: '',
        nickName: '',
        city: '',
        state: '',
        zipCode: '',
        country: ''
      },
      showSuccessAlert: false,
      isLoading: false, // Loading state for the form
      userId: sessionStorage.getItem('userId'), // Retrieve user ID from sessionStorage
    };
  },

  async created() {
    if (this.userId) {
      await this.fetchUserProfile();
    } else {
      console.error("No user ID found in sessionStorage");
      // Handle the case when userId is not available
    }
  },
  methods: {
    async logout() {
        try {
            const response = await axios.post('https://us-central1-beautlyn.cloudfunctions.net/api/logout');
            
            if (response.data.success) {
            // Clear any user data or tokens from local storage or cookies
            sessionStorage.removeItem('userToken'); // Example if you're using Session storage
            sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('alertSuccess');

            // Redirect to the login page
            this.$router.push('/portal-login');
            } else {
            console.error('Error logging out:', response.data.message);
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    },//Logout End
    async fetchUserProfile() {
      try {
        const docRef = doc(db, "users", this.userId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          this.userProfile = docSnap.data();
        } else {
          console.log("No such document!");
          // Handle the case when the document doesn't exist
        }
      } catch (e) {
        console.error("Error fetching user profile:", e);
        // Handle the error appropriately
      }
    },
    async updateProfile() {        
        this.isLoading = true; // Start loading
      try {
        const docRef = doc(db, "users", this.userId);
        await updateDoc(docRef, this.userProfile);
        this.showSuccessAlert = true;
        this.$forceUpdate(); // Force the component to update

        console.log("Profile updated successfully");
        // Automatically hide the alert after 3 seconds
        setTimeout(() => {
          this.showSuccessAlert = false;
          this.$forceUpdate(); // Force update again after changing the flag
        }, 3000);
      } catch (e) {
        console.error("Error updating profile:", e);
        // Handle the error appropriately
      }finally {
        this.isLoading = false; // Stop loading
      }

      this.showSuccessAlert = true;

setTimeout(() => {
    console.log('Hiding alert');
  this.showSuccessAlert = false;
}, 3000); // Hide the alert after 3 seconds

    }
  }
};
</script>
  
  <style>
  /* Add styles if needed */
  </style>
  