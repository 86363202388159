<template>
  <div>
    <PortalNav @logout-event="logout"></PortalNav>
    <!-- Main Content -->
    <div class="container mt-4">
      <div class="row">
        <!-- Sidebar -->
        <PortalSidebar/>
        <!-- Dynamic Content based on Role -->
        <div class="col-md-9">
            <!-- Content specific to Technical Manager Pages -->
          <div v-if="userRole === 'Technical Manager'">
            Content specific to Technical Manager Pages
          </div>
          <div v-if="userRole === 'customer'">
            <h1>Welcome new customer</h1>
            <p>Go to Front End to book your Appointment </p>
          </div>
          <div v-if="userRole === 'beautician'">
            <!-- Entry Form -->
            <p>Create your new services in "Add My Services" Today!!</p>
          </div>
          <!-- ... and so on for other roles -->
        </div>
      </div>
    </div>
  </div>
    <br><br><br><br><br><br><br>
    <AppFooter/>
  </template>
  
<script>
  import axios from 'axios';
  import PortalNav from '../../components/backend/PortalNav.vue';
  import AppFooter from '../../components/AppFooter.vue';
  import { ref } from 'vue';
  import PortalSidebar from './PortalSidebar.vue';
  export default {
    name: 'AdminDashboard',
    components: { AppFooter, PortalNav, PortalSidebar },
    data() {
        return {
        userRole: '', // This should be set based on the logged-in user's role
        logoImage: require('@/assets/logo.png'),
        services: [], //Fetch Services
        currentPage: 1,
        itemsPerPage: 6
        };
    },
    async created() {
        // Fetch the user's role after the component is created
        // For demonstration purposes, I'm setting it manually
        //this.userRole = 'admin1'; // This value should come from your authentication system or state management
        // Fetch the user's role from session storage
        this.userRole = sessionStorage.getItem('userRole');
        //Fetch the Services for the Beautician
        try {
            const response = await axios.get('https://us-central1-beautlyn.cloudfunctions.net/api/fservices'); // Adjust the URL if needed
            this.services = response.data;
          } catch (error) {
            console.error('Error fetching services:', error);
          }
    },
    methods: {
        //Logout
        async logout() {
        try {
            const response = await axios.post('https://us-central1-beautlyn.cloudfunctions.net/api/logout');
            
            if (response.data.success) {
            // Clear any user data or tokens from local storage or cookies
            sessionStorage.removeItem('userToken'); // Example if you're using Session storage
            sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('alertSuccess');
            
            // Redirect to the login page
            this.$router.push('/portal-login');
            } else {
            console.error('Error logging out:', response.data.message);
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
        },
        //Services List for the Beautician, naming the days from date
        getDayName(dateString) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = new Date(dateString);
        return days[date.getDay()];
      },
      //Service List Pagination
      goToPage(page) {
        this.currentPage = page;
      }
    },
    //Form for the beautician default values
    setup() {
      const serviceData = ref({
        service: 'Haircut',
        cost: 30,
        date: new Date().toISOString().substr(0, 10)
      });
      return {
      serviceData
    };
    },
    //Services List Pagination
    computed: {
      paginatedServices() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.services.slice(start, end);
      },
      totalPages() {
        return Math.ceil(this.services.length / this.itemsPerPage);
      }
    },
    watch: {
      services(newServices, oldServices) {
        if (newServices !== oldServices) {
          this.currentPage = 1;
        }
      }
    }
  }
</script>
<style scoped>
.pagination .active .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: white; /* Set the text color for the active link */
}

.pagination .page-link {
  color: var(--primary-color); /* Set the default text color for the links */
}

.pagination .page-link:hover {
  background-color: var(--primary-color);
  color: white; /* Set the text color for the hovered link */
}
</style>

