<template>
  <PortalNav @logout-event="logout"></PortalNav>
  <!-- Main Content -->
  <div class="container mt-4">
        <div class="row">
            <!-- Sidebar -->
            <PortalSidebar/>
            <div class="col-md-9">
                    <!-- Entry Form -->
                    <div class="card">
                        <div class="card-body">
                            <h3 class="text-center mb-4">My Appointment</h3>
                            <!-- Bootstrap 5 Progress Bar for Loading Animation -->
                            <div v-if="isLoading" class="progress">
                              <div class="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  :style="{ width: progress + '%' }"
                                  aria-valuenow="progress"
                                  aria-valuemin="0"
                                  aria-valuemax="100">
                                {{ progress }}%
                              </div>
                            </div>
                            <!-- Data Table -->
                            <table v-else class="table table-bordered table-striped">
                              <thead class="thead-dark">
                                <tr>
                                  <th>Date</th>
                                  <th>Service Type</th>
                                  <th>Time</th>
                                  <th>Approval</th>
                                  <th>Payment Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="appointment in appointments" :key="appointment.id">
                                  <td>{{ formatDate(appointment.createdAt) }}</td>
                                  <td>{{ appointment.serviceType }}</td>
                                  <td>{{ appointment.startTime }}</td>
                                  <td>{{ appointment.status }}</td>
                                  <td>{{ appointment.paymentStatus }}</td>
                                </tr>
                              </tbody>
                            </table>
                        </div> 
                    </div>
            </div>
        </div> 
    </div>    
    <br><br><br><br>
  <AppFooter/>
</template>
<script>
import axios from 'axios';
import PortalNav from '../../components/backend/PortalNav.vue';
import AppFooter from '../../components/AppFooter.vue';
import PortalSidebar from '../../components/backend/PortalSidebar.vue';
//For the Firebase 
import { ref, onMounted } from 'vue';
import { db } from '@/firebaseConfig'; // Adjust the path according to your Firebase configuration file
import { collection, getDocs, query, where} from 'firebase/firestore';

export default {
    name: 'MyAppointment',
    components: { AppFooter, PortalNav, PortalSidebar },
    methods: {
      async logout() {
        try {
            const response = await axios.post('https://us-central1-beautlyn.cloudfunctions.net/api/logout');
            
            if (response.data.success) {
            // Clear any user data or tokens from local storage or cookies
            sessionStorage.removeItem('userToken'); // Example if you're using Session storage
            sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('alertSuccess');

            // Redirect to the login page
            this.$router.push('/portal-login');
            } else {
            console.error('Error logging out:', response.data.message);
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
        }//Logout End
    },//method End
    setup() {
      const appointments = ref([]);
      const isLoading = ref(true); // Loading state
      const progress = ref(0);//progress bar
      const logid = sessionStorage.getItem('userId'); // Retrieve the user ID from sessionStorage

      const incrementProgress = () => {
        if (progress.value < 100) {
          progress.value += 10; // Increment by 5% or as needed
          setTimeout(incrementProgress, 100); // Adjust timing as needed
        }
      };

      const fetchAppointments = async () => {
        incrementProgress(); // Start incrementing progress
        isLoading.value = true; // Start loading
        if (logid) {
          const q = query(collection(db, "appointments"), where("userId", "==", logid));
          const querySnapshot = await getDocs(q);
          appointments.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        }
        setTimeout(() => {
          isLoading.value = false;
          progress.value = 100; // Ensure progress completes
        }, 200);
      };

      const formatDate = (timestamp) => {
        if (timestamp) {
          const date = timestamp.toDate();
          const year = date.getFullYear();
          const month = date.toLocaleString('default', { month: 'short' });
          const day = date.getDate();

          return `${month} ${day}, ${year}`;
        }
        return '';
      };

      onMounted(fetchAppointments);

      return { appointments, formatDate, isLoading, progress, incrementProgress };
    }//Setup End
}
</script>