<template>
    <NavBar/>
    <div class="container mt-5">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">Sign Up</div>
            <div class="card-body">
              <!-- Display success message -->
              <div v-if="successMessage" class="alert alert-success">
                {{ successMessage }}
              </div>
              <!-- Display error message -->
              <div v-if="errorMessage" class="alert alert-danger">
                {{ errorMessage }}
              </div>
              <form @submit.prevent="register">
                <div class="mb-3">
                  <label for="email" class="form-label">Email:</label>
                  <input v-model="email" type="email" class="form-control" id="email" required />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Password:</label>
                  <input v-model="password" type="password" class="form-control" id="password" required />
                </div>
                <div class="mb-3">
                  <label for="userRole" class="form-label">User Type:</label>
                  <select v-model="userRole" class="form-select" id="userRole">
                    <option value="customer">Customer</option>
                    <option value="beautician">Beautician</option>
                  </select>
                </div>
                <button type="submit" class="btn btn-primary">Register</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <AppFooter/>
</template>
  
<script>
  import axios from 'axios';
  import AppFooter from '../../components/AppFooter.vue';
  import NavBar from '../NavBar.vue';
  export default {
    data() {
        return {
            email: '',
            password: '',
            userRole: 'customer', // Setting default value to "customer"
            errorMessage: '', // Error message
            successMessage: '' // Success message
        };
    },
    methods: {
    async register() {
      try {
        const response = await axios.post('https://us-central1-beautlyn.cloudfunctions.net/api/register', {
          email: this.email,
          password: this.password,
          userRole: this.userRole
        });
        if (response.data.success) {
          this.successMessage = 'User created successfully!'; // Set the success message
          setTimeout(() => {
            this.$router.push('/portal-login');
          }, 2000); // Redirect after 2 seconds to give the user time to read the message
        } else {
            this.errorMessage = response.data.message;
        }
      } catch (error) {
        this.errorMessage = 'An unexpected error occurred. Please try again.';
      }
    }
  },
  components: { AppFooter, NavBar }
};
</script>  