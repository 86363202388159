<template>
        <NavBar />
        <!-- Contact Page -->
        <div class="contact-section">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8">
                <div class="card">
                  <div class="card-body">
                    <h3 class="text-center mb-4">Contact Us</h3>
                    <p class="text-center">Let's talk about everything!</p>
                    <p class="text-center mb-4">We value your input and look forward to connecting with you. Your voice matters to us, so don't hesitate to reach out.</p>
                    <form>
                      <div class="mb-3">
                        <input type="text" class="form-control" placeholder="Name">
                      </div>
                      <div class="mb-3">
                        <input type="email" class="form-control" placeholder="Email">
                      </div>
                      <div class="mb-3">
                        <textarea class="form-control" rows="5" placeholder="Message"></textarea>
                      </div>
                      <div class="text-center">
                        <button type="submit" class="btn btn-primary">Send Message</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
</template>
<script>
import NavBar from './NavBar.vue';
import AppFooter from './AppFooter.vue';

export default {
  components: {
    NavBar,
    AppFooter
  }
  // rest of your ContactPage script
}
</script>
<style scoped>
.contact-section {
  background: #f9f9f9;
  padding: 50px 0; 
}

.card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

h3 {
  font-size: 24px;
  font-weight: 700;
}

p {
  color: #777;
}

.form-control {
  border: 1px solid #e5e5e5;
  border-radius: 30px;
  padding: 20px 30px;
  font-size: 16px;
  transition: all 0.3s;
}

.form-control:focus {
  border-color: #333;
  box-shadow: none;
}

.btn-primary {
  border: none;
  border-radius: 30px;
  padding: 10px 30px;
  font-size: 16px;
  transition: all 0.3s;
}
</style>