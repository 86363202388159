import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/HomePage.vue';
import ContactPage from '../components/ContactPage.vue';
import AdminLogin from '../components/backend/AdminLogin.vue';
import AdminDashboard from '../components/backend/AdminDashboard.vue';
import PortalRegistration from '../components/backend/PortalRegistration.vue';
import GeneralServices from '../components/GeneralServices.vue';
import GeneralProducts from '../components/GeneralProducts.vue';
import GeneralAbout from '../components/GeneralAbout.vue';
import MyAppointment from '../components/customer/MyAppointment.vue';
import ReceivedAppointment from '../components/beautician/ReceivedAppointment.vue';
import MyServices from '../components/beautician/MyServices.vue';
import ViewMyServices from '../components/beautician/ViewMyServices.vue';
import UserProfile from '../components/backend/UserProfile.vue';

const routes = [
  { path: '/', component: HomePage, name: 'home' },
  { path: '/contact', component: ContactPage, name: 'contact' },
  { path: '/portal-login', component: AdminLogin, name: 'portal-login' },
  { path: '/portal-dashboard', component: AdminDashboard, name: 'portal-dashboard' },
  { path: '/portal-registration', component: PortalRegistration, name: 'portal-registration' },
  { path: '/services', component: GeneralServices, name: 'services' },
  { path: '/products', component: GeneralProducts, name: 'products' },
  { path: '/about', component: GeneralAbout, name: 'about' },
  { path: '/my-appointment', component: MyAppointment, name: 'my-appointment' },
  { path: '/received-appointment', component: ReceivedAppointment, name: 'received-appointment' },
  { path: '/my-services', component: MyServices, name: 'my-services' },
  { path: '/view-my-services', component: ViewMyServices, name: 'view-my-services' },
  { path: '/user-profile', component: UserProfile, name: 'user-profile' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Check authentication before each route
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!sessionStorage.getItem('userToken'); // Convert to boolean
  
  //Console Logging: Add console.log statements in your navigation guard to debug the flow:
  console.log('Is Authenticated:', isAuthenticated);
  console.log('Navigating to:', to.name);

  // ... rest of the code
  if (to.name === 'portal-login' && isAuthenticated) {
      return next({ name: 'portal-dashboard' }); // Redirect to dashboard if trying to access login while authenticated
  }
  if (to.name === 'portal-registration' && isAuthenticated) {
    return next({ name: 'portal-dashboard' }); // Redirect to dashboard if trying to access registration while authenticated
  } 
  if (to.name === 'portal-dashboard' && !isAuthenticated) {
      return next({ name: 'portal-login' }); // Redirect to login if trying to access dashboard without authentication
  }
  next(); // Continue as normal for all other routes
});

export default router;