<template>
      <!-- Navbar -->
      <nav class="navbar navbar-expand-lg">
      <div class="container d-flex justify-content-between">
          <!-- Logo -->
          <router-link class="navbar-brand" to="/"><img :src="logoImage" alt="Beaut Lyn Logo" width="170"></router-link>
          
          <!-- Centered Brand Text -->
          <router-link class="navbar-brand mx-auto border" to="/portal-dashboard">Dashboard for {{ capitalizedUserRole }}</router-link>    
          
          <!-- Right Aligned Items -->
          <div>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarNav">
                  <ul class="navbar-nav ml-auto">
                      <li class="nav-item">
                        <router-link class="nav-link" to="/portal-dashboard">Dashboard</router-link>    
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" href="/user-profile">Profile</a>
                      </li>
                      <!-- <li class="nav-item">
                          <a class="nav-link" href="#">Settings</a>
                      </li> -->
                      <li class="nav-item">
                          <a class="nav-link" href="#" @click.prevent="emitLogout">Logout</a>
                      </li>
                  </ul>
              </div>
          </div>
      </div>
  </nav>
</template>
<script>
export default {  
    data() {
        return {
        logoImage: require('@/assets/logo.png')
        }
    },
    name: 'PortalNav',
    methods: {
        emitLogout() {
        this.$emit('logout-event');
        }
    },
    created() {
        // Fetch the user's role from session storage
        this.userRole = sessionStorage.getItem('userRole');
    },
    computed: {
        capitalizedUserRole() {
            if (!this.userRole) return '';
            return this.userRole.charAt(0).toUpperCase() + this.userRole.slice(1);
        }
    }
}
</script>
<style scoped>
/* Highlight the active link */
.router-link-exact-active {
    text-decoration: underline;
    color: var(--secondary-color) !important;
}
</style>