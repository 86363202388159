<template>
    <NavBar />
    <div class="container mt-5">
      <h2 class="text-center mb-4">About Us</h2>
      <div class="row mb-5">
        <div class="col-md-6">
            <img src="https://images.unsplash.com/photo-1524502397800-2eeaad7c3fe5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwxfHxiZWF1dHklMjBzYWxvbiUyMGludGVyaW9yfGVufDB8fHx8MTY5NzMxNDE1M3ww&ixlib=rb-4.0.3&q=80&w=400" alt="Salon Interior" class="img-fluid mb-3">
        </div>
        <div class="col-md-6">
          <br><br><br><br><br>  
          <h4>Unleash Your Inner Beauty with Beautlyn!</h4>
          <p>Welcome to Beautlyn, your premier destination for beauty and rejuvenation. At Beautlyn, we believe that true beauty shines from within, and we are dedicated to enhancing your natural radiance. Our salon offers a haven of tranquility, where our expert team of skilled professionals provides a wide range of beauty services tailored to your unique needs. From luxurious skincare treatments to stunning hair makeovers and flawless manicures, we strive to exceed your expectations. Embrace a pampering experience like no other as we combine top-notch products, innovative techniques, and personalized care. Step into Beautlyn and let us unveil the beauty that lies within you.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <h4>Experience the Perfect Style at Beautlyn - Where Professionalism Meets Variety</h4>
          <p>Our salon proudly offers an extensive range of treatments tailored to your unique needs. From luxurious products to exceptional treatments, every aspect of your salon experience is meticulously crafted. Whether it's a rejuvenating facial, a stylish haircut, or any of our exceptional services, we are dedicated to providing top-notch service. Step into Beautlyn and let us redefine your beauty journey with our exceptional expertise and attention to detail.</p>
        </div>
        <div class="col-md-6">
          <img src="https://images.unsplash.com/photo-1527799820374-dcf8d9d4a388?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwyfHxiZWF1dHklMjBzYWxvbiUyMGludGVyaW9yfGVufDB8fHx8MTY5NzMxNDE1M3ww&ixlib=rb-4.0.3&q=80&w=400" alt="Salon Product" class="img-fluid mb-3">
        </div>
      </div>
    </div><br>
    <AppFooter />
  </template>
  
  <script>
    import NavBar from './NavBar.vue';
    import AppFooter from './AppFooter.vue';
  export default {
    name: "GeneralAbout",
    components: {
    NavBar,
    AppFooter
    }
  };
  </script>
  
  <style scoped>
  /* You can add any additional styles specific to this component here */
  </style>
  