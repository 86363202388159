<template>
  <PortalNav @logout-event="logout"></PortalNav>
  <!-- Main Content -->
  <div class="container mt-4">
    <div class="row">
      <!-- Sidebar -->
      <PortalSidebar/>
      <div class="col-md-9">
        <!-- Entry Form -->
        <div class="card">
          <div class="card-body">
            <h3 class="text-center mb-4">View My Services</h3>
            <!-- Bootstrap 5 Progress Bar for Loading Animation -->
            <div v-if="isLoading" class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated" 
                  role="progressbar"
                  :style="{ width: progress + '%' }"
                  aria-valuenow="progress"
                  aria-valuemin="0"
                  aria-valuemax="100">
                  {{ progress }}%
              </div>
            </div>            
            <!-- Data Table -->
            <table v-else class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th scope="col">S.N</th>
                  <th scope="col">Date</th>
                  <th scope="col">Service Name</th>
                  <th scope="col">Service Type</th>
                  <th scope="col">Cost ($)</th>
                  <th scope="col">Duration (min.)</th>
                  <th scope="col">Time Slots</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(service, index) in viewServices" :key="service.id">       
                  <td>{{ index + 1 }}</td> <!-- Displaying the serial number -->           
                  <td>{{ formatDate(service.date) }}</td>
                  <td>{{ getServiceCategoryName(service.serviceTypeId) }}</td>
                  <td>
                    <span v-for="option in service.serviceTypeOptions" :key="option" class="badge bg-danger-subtle text-danger-emphasis rounded-pill">{{ option }}</span>
                  </td>
                  <td>{{ service.cost }}</td>
                  <td>{{ service.duration }}</td>
                  <td>
                    <span v-for="time in service.startTime" :key="time" class="badge bg-danger-subtle text-danger-emphasis rounded-pill">{{ time }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>      
    </div>  
  </div>
  <br><br><br><br>
  <AppFooter/>
</template>

<script>
import axios from 'axios';
import PortalNav from '../../components/backend/PortalNav.vue';
import AppFooter from '../../components/AppFooter.vue';
import PortalSidebar from '../../components/backend/PortalSidebar.vue';
import { ref, onMounted } from 'vue';
import { db } from '@/firebaseConfig';
import { collection, getDocs, query, where} from 'firebase/firestore';

export default {
  name: 'ViewMyServices',
  components: { AppFooter, PortalNav, PortalSidebar },
  data() {
    return {
      services: [],
    };
  },
  methods: {
    async logout() {
        try {
            const response = await axios.post('https://us-central1-beautlyn.cloudfunctions.net/api/logout');
            
            if (response.data.success) {
            // Clear any user data or tokens from local storage or cookies
            sessionStorage.removeItem('userToken'); // Example if you're using Session storage
            sessionStorage.removeItem('userRole');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('alertSuccess');

            // Redirect to the login page
            this.$router.push('/portal-login');
            } else {
            console.error('Error logging out:', response.data.message);
            }
        } catch (error) {
            console.error('An error occurred during logout:', error);
        }
    },//Logout End
  },//method end
  setup() {
    const viewServices = ref([]);
    const serviceCategories = ref([]);
    const isLoading = ref(true); // Loading state
    const progress = ref(0);//progress bar
    const SessionUserID = sessionStorage.getItem('userId'); // Retrieve the user ID from sessionStorage

    const incrementProgress = () => {
        if (progress.value < 100) {
          progress.value += 10; // Increment by 5% or as needed
          setTimeout(incrementProgress, 100); // Adjust timing as needed
        }
    };

    const fetchServices = async () => {
      incrementProgress(); // Start incrementing progress
      isLoading.value = true; // Start loading
      if (SessionUserID) {
        const q = query(collection(db, "services"), where("userId", "==", SessionUserID));
        const querySnapshot = await getDocs(q);
        // Map and sort the data
        viewServices.value = querySnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date in descending order    
      } 
      setTimeout(() => {
        isLoading.value = false;
        progress.value = 100; // Ensure progress completes
      }, 200);
    };

    const fetchServiceCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "serviceCategory"));
        serviceCategories.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      } catch (error) {
        console.error("Error fetching service categories:", error);
      }
    };

    const getServiceCategoryName = (serviceTypeId) => {
      const category = serviceCategories.value.find(cat => cat.id === serviceTypeId);
      return category ? category.name : 'Unknown Category';
    };

    const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();

    return `${month} ${formatDay(day)}, ${year}`;
  }
  return '';
};

const formatDay = (day) => {
  if (day > 3 && day < 21) return day + 'th';
  switch (day % 10) {
    case 1:  return day + "st";
    case 2:  return day + "nd";
    case 3:  return day + "rd";
    default: return day + "th";
  }
}

    onMounted(async () => {
      await fetchServices();
      await fetchServiceCategories();
    });

    return { viewServices, formatDate, isLoading, progress, incrementProgress, getServiceCategoryName };  
  }//Setup End  
};
</script>

<style>
.table td, .table th {
  text-align: center;
  vertical-align: middle;
}
</style>
