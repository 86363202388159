<template>
  <div v-if="isModal" class="modal-backdrop" @click="closeModal">
    <div class="modal-content">
      <!-- Modal-specific content (e.g., just the login form) -->
      <!-- Place your form and other modal contents here -->
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="card">
              <!-- Modal close button -->
              <div class="card-header text-center">
                  <div class="modal-header">
                    <h5>Login</h5>
                    <button type="button" class="modal-close-btn btn-close justify-content-right" aria-label="Close" @click="$emit('close-modal')"></button>
                  </div>
              </div>
              <div class="card-body">
                <!--Error Message-->
                <div v-if="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <form @submit.prevent="login">
                  <div class="mb-3">
                    <label for="email" class="form-label">Email address</label>
                    <input type="email" class="form-control" id="email" v-model="email" required>
                  </div>
                  <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input type="password" class="form-control" id="password" v-model="password" required>
                  </div>
                  <div class="d-flex align-items-center">
                  <button type="submit" class="btn btn-primary me-2" :disabled="isLoading">
                      <span v-if="isLoading">Logging in...</span>
                      <span v-else>Login</span>
                  </button>
                  <span class="me-2">or</span> <router-link class="btn btn-primary" to="/portal-registration">Sign Up</router-link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
  <div v-else>
    <!-- Full page layout -->
    <NavBar/>
    <div class="container mt-5">
      <!-- ... rest of your existing template ... -->
      <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="card">
              <div class="card-header text-center">
                  <h5>Login</h5>
              </div>
              <div class="card-body">
                <!--Error Message-->
                <div v-if="errorMessage" class="alert alert-danger">
                    {{ errorMessage }}
                </div>
                <form @submit.prevent="login">
                  <div class="mb-3">
                    <label for="email" class="form-label">Email address</label>
                    <input type="email" class="form-control" id="email" v-model="email" required>
                  </div>
                  <div class="mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input type="password" class="form-control" id="password" v-model="password" required>
                  </div>
                  <div class="d-flex align-items-center">
                  <button type="submit" class="btn btn-primary me-2" :disabled="isLoading">
                      <span v-if="isLoading">Logging in...</span>
                      <span v-else>Login</span>
                  </button>
                  <span class="me-2">or</span> <router-link class="btn btn-primary" to="/portal-registration">Sign Up</router-link>
                  </div>
                </form>
              </div>
            </div><br><br><br><br><br><br><br>
          </div>
        </div>
    </div>
    <AppFooter/>
  </div>

  </template>
  
<script>
  import axios from 'axios';
  import NavBar from '../../components/NavBar.vue';
  import AppFooter from '../../components/AppFooter.vue';
  //for the logo 
  export default {
    //For the Modal or not
    props: {
    isModal: {
      type: Boolean,
      default: false,
      },
    },
    data() {
        return {
          email: '',
          password: '',
          errorMessage: '', // Error Message
          isLoading: false // Lodaing for the form
        };
    },
    //For the login 
    methods: {
        async login() {
          this.isLoading = true;
            try {
                const response = await axios.post('https://us-central1-beautlyn.cloudfunctions.net/api/login', {
                    email: this.email,
                    password: this.password
                });
                if (response.data.success) {
                    // Store the token in sessionStorage
                    sessionStorage.setItem('userToken', response.data.token);
                    sessionStorage.setItem('userRole', response.data.role);
                    sessionStorage.setItem('userId', response.data.user);
                    sessionStorage.setItem('alertSuccess', response.data.success);
                    // Handle successful login, e.g., redirect to dashboard or store token
                    //console.log(response.data.message);
                    if (this.isModal) {
                      // In modal context, just emit close-modal event
                      this.$emit('close-modal');// Emit an event to close the modal
                    } else {
                      // In full page context, navigate to dashboard
                      this.$router.push('/portal-dashboard');
                    }
                }
                else {
                    // Handle failed login, e.g., show an error message
                    //console.error(response.data.message);
                    this.errorMessage = response.data.message; // Set the error message
                }
            }
            catch (error) {
              //console.error('An error occurred:', error);
              this.errorMessage = 'An unexpected error occurred. Please try again.';
            }
          this.isLoading = false;
        },
        // For the modal close clicking outside
        closeModal(event) {
          if (event.target.classList.contains('modal-backdrop')) {
            this.$emit('close-modal');
          }
        }
    },
    components: { AppFooter, NavBar }
}

</script>
<style>
.btn-primary {
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  transition: all 0.3s;
}

/* Modal Styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Adjust width, max-width as needed */
}
</style>  