<template>
  <div v-if="show" class="booking-form">
    <hr class="border-bottom border-1 border-dark">
    <h3>Booking Appointment</h3>
    <form @submit.prevent="submitBooking">
      <!-- Display the booking details here -->
      <div class="mb-3">
        <label for="nickName" class="form-label">Service Provider</label>
        <input type="text" class="form-control" id="nickName" v-model="localBookingDetails.nickName" readonly>
      </div>
      <div class="mb-3">
        <label for="city" class="form-label">City</label>
        <input type="text" class="form-control" id="city" v-model="localBookingDetails.city" readonly>
      </div>
      <div class="mb-3">
        <label for="cost" class="form-label">Price($)</label>
        <input type="text" class="form-control" id="cost" v-model="localBookingDetails.cost" readonly>
      </div>
      <div class="mb-3">
        <label for="duration" class="form-label">Duration(Minutes)</label>
        <input type="text" class="form-control" id="duration" v-model="localBookingDetails.duration" readonly>
      </div>

      <!-- Service Type -->
      <div class="mb-3">
        <label for="serviceType" class="form-label">Service Type</label>
        <select class="form-control" id="serviceType" v-model="localBookingDetails.serviceType" required>
          <option disabled value="" selected>Choose an option</option>
          <option v-for="stype in serviceTypeOptionss" :key="stype" :value="stype">{{ stype }}</option>
        </select>
      </div>
      
      <!-- Time Slot -->
      <div class="mb-3">
        <label for="startTime" class="form-label">Time Slot</label>
        <select class="form-control" id="startTime" v-model="localBookingDetails.startTime" required>
          <option disabled value="" selected>Choose an option</option>
          <option v-for="time in startTimeOptions" :key="time" :value="time">{{ time }}</option>
        </select>
      </div>

      <!-- Notes -->
      <div class="mb-3">
        <label for="notes" class="form-label">Notes</label>
        <textarea class="form-control" id="notes" v-model="localBookingDetails.notes" required></textarea>
      </div>      
      <!-- Add other form fields as needed -->
      <button type="submit" class="btn btn-primary" :disabled="isLoading">
        <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span v-if="isLoading">Loading...</span>
        <span v-else>Submit Booking</span>
      </button>
      <button @click="cancelBooking" type="button" class="btn btn-danger">Cancel</button>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    bookingDetails: {
      type: Object,
      required: true
    },
    startTimeOptions: {
      type: Array,
      required: false,
      default: () => []
    },
    serviceTypeOptionss: {
      type: Array,
      required: false,
      default: () => []
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localBookingDetails: { ...this.bookingDetails }
    };
  },
  watch: {
    startTimeOptions(newVal) {
      console.log('startTimeOptions updated:', newVal);
    },
    bookingDetails: {
      handler(newVal) {
        console.log('bookingDetails updated:', newVal);//testing value
        this.localBookingDetails = { ...newVal };
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    submitBooking() {
      this.$emit('submit-booking', this.localBookingDetails);
    },
    cancelBooking() {
      this.$emit('cancel-booking');
    }
  }
};
</script>

<style scoped>
.booking-form {
  color: #000;
  box-shadow: 0px 4px 8px #fff;
}
.btn-danger {
  border-radius: 30px;
  margin-left: 5px;
  padding: 10px 20px;
}
</style>