<template>
    <NavBar />
    <div class="container mt-5">
    <h2 class="text-center mb-4">Services</h2>
    <div class="row">
      <div class="col-md-4 mb-4" v-for="(service, index) in services" :key="index">
        <div class="card">
          <img :src="service.image" class="service-img card-img-top" :alt="service.title">
          <div class="card-body">
            <h5 class="card-title">{{ service.title }}</h5>
            <p class="card-text">{{ service.price }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    <AppFooter />
  </template>
  
  <script>
    import NavBar from './NavBar.vue';
    import AppFooter from './AppFooter.vue';
  export default {
    name: "GeneralServices",
    components: {
    NavBar,
    AppFooter
  },
  data() {
    return {
      services: [
        { title: "Haircut", price: "$50", image: "https://images.unsplash.com/photo-1595475884562-073c30d45670?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwxfHxiZWF1dHklMjBzYWxvbiUyMHNlcnZpY2VzfGVufDB8fHx8MTY5NzMxMjk5OXww&ixlib=rb-4.0.3&q=80&w=400" },
        { title: "Treaming", price: "$30", image: "https://images.unsplash.com/photo-1525517710769-9f4fdb9e4099?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwyfHxiZWF1dHklMjBzYWxvbiUyMHNlcnZpY2VzfGVufDB8fHx8MTY5NzMxMjk5OXww&ixlib=rb-4.0.3&q=80&w=400" },
        { title: "Coloring", price: "$70", image: "https://images.unsplash.com/photo-1626383126210-15c15e62d9ba?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwzfHxiZWF1dHklMjBzYWxvbiUyMHNlcnZpY2VzfGVufDB8fHx8MTY5NzMxMjk5OXww&ixlib=rb-4.0.3&q=80&w=400" },
        { title: "Facial", price: "$60", image: "https://images.unsplash.com/photo-1507652313519-d4e9174996dd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHw0fHxiZWF1dHklMjBzYWxvbiUyMHNlcnZpY2VzfGVufDB8fHx8MTY5NzMxMjk5OXww&ixlib=rb-4.0.3&q=80&w=400" },
        { title: "Manicure", price: "$40", image: "https://images.unsplash.com/photo-1454944338482-a69bb95894af?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHw1fHxiZWF1dHklMjBzYWxvbiUyMHNlcnZpY2VzfGVufDB8fHx8MTY5NzMxMjk5OXww&ixlib=rb-4.0.3&q=80&w=400" },
        { title: "Pedicure", price: "$45", image: "https://images.unsplash.com/photo-1595475884562-073c30d45670?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w0NDgzMDl8MHwxfHNlYXJjaHwxfHxiZWF1dHklMjBzYWxvbiUyMHNlcnZpY2VzfGVufDB8fHx8MTY5NzMxMjk5OXww&ixlib=rb-4.0.3&q=80&w=400" }
      ]
    };
  }
  };
  </script>
  
<style scoped>
  .service-img{
    height:40vh;
  }
</style>
  